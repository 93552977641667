import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Argon from './plugins/argon-kit';
import store from './store';
import axios from 'axios';

// Import plugin
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';

const settingsUrl =
  process.env.NODE_ENV === 'production' ? '/settings.json' : '/settings-dev.json';

axios
  .get(settingsUrl)
  .then(response => {
    if (response.data) {
      store.commit('setSettings', response.data);
    } else {
      store.commit('setSettingsError', 'No data in settings response');
    }
  })
  .catch(error => {
    store.commit('setSettingsError', error);
  })
  .finally(() => {
    createApp(App)
      .use(store)
      .use(router)
      .use(Argon)
      .use(vuetify)
      .use(i18n)
      .mount('#app');
  });
<template>
    <div class="loading-text">
        <div class="rotating-ring"></div>
    </div>
</template>
<style lang="scss">
.rotating-ring {
    width: 30px;
    height: 30px;
    border: 4px solid #cccccc00;
    border-top: 4px solid #A3ABBF;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>


<template>
  <footer class="footer">
    <!-- Footer last line -->
    <div id="last-line" class="container">      
      <div class="row align-items-center justify-content-center text-center">
        <span class="text-white">
        &copy; {{ year }} Powered By GraviTalent Kft. - <a class="text-white" href="https://benchmark.games/#privacy" target="_blank">Privacy</a>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>

export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
};
</script>

<style lang="scss" scoped>
#last-line {
  max-width: 100% !important;
  height: 50px;
  background-color: rgba(46, 53, 72, 0.9);

  .row {
    margin: auto;
    height: 100%;

    .last-line-col {
      margin: auto;
      height: 100%;
      align-items: center;
    }
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";

// import Header from "./layout/AppHeader";
import Footer from "../layout/AppFooter.vue";
import BGHome from "../views/BGHome.vue";
import BGLogin from "../views/BGLogin.vue";
import BGResetPassword from "../views/BGResetPassword.vue";
import BGNewPasswordSetting from "../views/BGNewPasswordSetting.vue"
import BGChangePassword from "../views/BGChangePassword.vue"
import BGTestRedirectAuthHandler from "../views/BGTestRedirectAuthHandler.vue";

import { ACCOUNT_SERVICE_TOKEN_KEY , ACCOUNT_SERVICE_password_change_required} from "../constants";

const checkServiceToken = (to, from, next) => {
  const localServiceToken = localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
  const sessionServiceToken = sessionStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
  const password_change_requiredLocal = localStorage.getItem(ACCOUNT_SERVICE_password_change_required);
  const password_change_requiredSession = sessionStorage.getItem(ACCOUNT_SERVICE_password_change_required);
  const serviceToken = localServiceToken || sessionServiceToken;
  const passwordRequired = password_change_requiredLocal || password_change_requiredSession;

  if (serviceToken) {
    if (passwordRequired != "undefined") {
      next("/change-password");
    } else {
      next("/");
    }
  } else {
    next();
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        default: BGHome,
        footer: Footer
      },
      beforeEnter: (to, from, next) => {
        const localServiceToken = localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
        const sessionServiceToken = sessionStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
        const password_change_requiredLocal = localStorage.getItem(ACCOUNT_SERVICE_password_change_required);
        const password_change_requiredSession = sessionStorage.getItem(ACCOUNT_SERVICE_password_change_required);
        const serviceToken = localServiceToken || sessionServiceToken;
        const passwordRequired = password_change_requiredLocal || password_change_requiredSession;
      
          if (serviceToken && passwordRequired != "undefined") {
            next("/change-password");
          } else {
            next();
          }
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        default: BGLogin,
        footer: Footer
      },
      beforeEnter: checkServiceToken
    },
    {
      path: "/logout",
      name: "logout",
      components: {
        default: BGLogin,
        footer: Footer
      },
      beforeEnter: (to, from, next) => {
        localStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
        sessionStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);

        localStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
        sessionStorage.removeItem(ACCOUNT_SERVICE_password_change_required);

        if (to.query.next) {
          window.location.href = to.query.next;
        } else {
          next("/")
        }
      }
    },
    {
      path: "/new-password",
      name: "new-password",
      components: {
        default: BGResetPassword,
        footer: Footer
      },
      beforeEnter: checkServiceToken
    },
    {
      path: "/change-password",
      name: "change-password",
      components: {
        default: BGChangePassword,
        footer: Footer
      },
      beforeEnter: (to, from, next) => {
        const localServiceToken = localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
        const sessionServiceToken = sessionStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
        const serviceToken = localServiceToken || sessionServiceToken;
  

        if (serviceToken) {
          next();
        } else {
          next("/");
        }
      }
    },
    {
      path: '/set-password',
      name: '/set-password',
      components: {
        default: BGNewPasswordSetting,
        footer: Footer
      },
      beforeEnter: checkServiceToken
    },
    {
      path: "/test-redirect/auth-handler",
      name: "test_redirect_auth_handler",
      components: {
        default: BGTestRedirectAuthHandler
      }
    },
    {
      path: "/oauth/authorize",
      name: "oauth_authorize",
      components: {
        default: BGLogin,
        footer: Footer
      },
    }
  ]
});

export default router;

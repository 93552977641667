<template>
  <div class="wrapper">
    <div class="page-header bg-default">
      <BGBackground />
      <BGHeaderLogo />

      <div class="home-content container" v-if="loggedIn">
        <h1 class="title">{{ $t("welcome") }}, {{ emailOrUsername }}</h1>
        <p v-if="developmentEnv">
          <b>access_token preview (DEV ONLY):</b> {{ accessTokenPreview }}
        </p>
        <p><b>{{ $t("email_user") }}:</b> {{ emailOrUsername }}</p>
        <p><b>{{ $t("home.def_language") }}:</b> {{ languageAS }}</p>

        <div class="home-btn-container">
          <base-button
            id="dashboard-button"
            type="info"
            class="home-btn mt-3 w-100"
            data-cy="dashboard"
            @click="open_dashboard"
          >
          {{ $t("button.dashboard") }}
          </base-button>
          <base-button
            id="change-password-button"
            type="info"
            class="home-btn mt-3 w-100"
            data-cy="change-password"
            @click="open_change_password"
          >
          {{ $t("button.change_password") }}
          </base-button>
          <base-button
            id="logout-button"
            type="danger"
            class="home-btn mt-3 w-100"
            data-cy="logout"
            @click="logout"
          >
          {{ $t("button.logout") }}
          </base-button>
        </div>
      </div>
      <div class="home-content container" v-else>
        <h1 class="title">{{ $t("home.welcome_to") }}</h1>
        <p class="login-text">
          {{ $t("home.login_here") }}
        </p>
        <router-link to="/login" name="login">
          <div class="home-btn-container">
            <base-button
              id="login-button"
              type="bg-gradient"
              class="home-btn mt-3 w-100"
              data-cy="login"
              to="/login"
            >
            {{ $t("button.login") }}
            </base-button>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ACCOUNT_SERVICE_TOKEN_KEY,
  ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY,
  ACCOUNT_SERVICE_password_change_required,
  ACCOUNT_SERVICE_LANGUAGE
} from "../constants";
import BGBackground from "./components/BGBackground.vue";
import BGHeaderLogo from "./components/BGHeaderLogo.vue";

export default {
  bodyClass: "register-page",

  components: {
    BGBackground,
    BGHeaderLogo
  },

  data() {
    return {
      loggedIn: true
    };
  },

  computed: {
    emailOrUsername() {
      const localEmailOrUsername = localStorage.getItem(
        ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY
      );
      const sessionEmailOrUsername = sessionStorage.getItem(
        ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY
      );
      return localEmailOrUsername
        ? localEmailOrUsername
        : sessionEmailOrUsername;
    },
    languageAS() {
      const localeLanguage = localStorage.getItem(
        ACCOUNT_SERVICE_LANGUAGE
      );
      const sessionLanguage = sessionStorage.getItem(
        ACCOUNT_SERVICE_LANGUAGE
      );
      return localeLanguage
        ? localeLanguage
        : sessionLanguage;
    },
    serviceToken() {
      const localServiceToken = localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY);
      const sessionServiceToken = sessionStorage.getItem(
        ACCOUNT_SERVICE_TOKEN_KEY
      );
      return localServiceToken ? localServiceToken : sessionServiceToken;
    },

    accessTokenPreview() {
      return this.serviceToken
        ? `${this.serviceToken.substring(0, 20)}...`
        : null;
    },
    developmentEnv() {
      let nodeEnv = typeof process !== 'undefined' && process.env
        ? process.env.NODE_ENV
        : null;
      return nodeEnv === "development";
    }
  },

  beforeMount() {
    if (!this.serviceToken) {
      this.loggedIn = false;
    }
  },

  methods: {
    logout() {
      localStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
      localStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);
      sessionStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
      sessionStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);

      localStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);
      sessionStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);

      localStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
      sessionStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
      this.loggedIn = false;
    },
    open_dashboard() {
      const dashboard_url = this.$store.getters.settings.dashboard_url;
      const environment = this.$store?.getters?.settings?.environment;
      window.open(dashboard_url, "dashboard" + environment).focus();
    },
    open_change_password() {
      this.$router.push(`/change-password`);
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  color: white;
}

.home-content {
  margin: 120px auto auto auto;
  background-color: transparent !important;
  box-shadow: none !important;
  width: 50% !important;

  .title {
    text-align: center;
  }

  .home-btn {
    height: 52px;
    max-width: 200px;
    margin: auto;
  }

  .home-btn-container {
    display: flex;
  }

  .login-text {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .home-content {
    width: 70% !important;
  }
}

@media screen and (max-width: 640px) {
  .home-content {
    width: 90% !important;
  }
}
</style>

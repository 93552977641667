<template>
  <div class="wrapper">
    <div class="page-header bg-default">
      <BGBackground />
      <BGHeaderLogo />

      <div class="container" id="login-container">
        <div class="form-container sign-in-container">
          <form action="#" role="form">
            <!-- Loading overlay -->
            <div v-if="loginLoading" class="form-loading-overlay" />

            <!-- Content -->
            <h5 class="mb-4">{{ $t("welcome") }}</h5>
            <div class="form-group mb-0">
              <!-- Email or username -->
              <label>{{ $t("email_user") }}</label>
              <div class="email-username--input-group input-group mb-3">
                <input v-model="emailOrUsername" id="emailOrUsername" type="text"
                  :class="{ 'input-error': !!emailOrUsernameError }" class="form-control" label="Email or username"
                  aria-label="Email or username" data-cy="email-or-username" @blur="emailOrUsernameVisited = true" />
              </div>
              <div v-if="emailOrUsernameError" class="form-error-label">
                {{ emailOrUsernameError }}
              </div>

              <!-- Password -->
              <label>{{ $t("password") }}</label>
              <div class="password-input-group input-group mb-3">
                <input v-model="password" :type="showPassword ? 'text' : 'password'" :class="{
                  'input-error': !!loginServerError || !!passwordError
                }" class="form-control" label="Password" aria-label="Password" autocomplete="current-password"
                  data-cy="password" @blur="passwordVisited = true" @keydown="handleKeyDown" v-on:keyup.enter="login" />
                <img class="password-visibility" :src="showPassword
                  ? '/img/ill/visibility-off.svg'
                  : '/img/ill/visibility-on.svg'
                  " @click="showPassword = !showPassword" />
              </div>
              <div v-if="passwordError" class="form-error-label">
                {{ passwordError }}
              </div>
              <div v-if="capsLockWarning" class="form-warning-label">
                {{ $t("login.capslock_on") }}
              </div>

              <!-- Remember be
              <base-checkbox class="mb-3" v-model="rememberMe">
                {{ $t("login.remember_me") }}
              </base-checkbox> -->
            </div>
            <base-button id="login-button" type="bg-gradient" class="mt-3 w-100" size="lg" data-cy="login" @click="login"
              :disabled="!!emailOrUsernameError || !!passwordError || emailOrUsernameVisited == false || passwordVisited == false">
              <span v-if="!loginLoading">{{ $t("button.login") }}</span>
              <span v-else>{{ $t("loading") }}...</span>
            </base-button>

            <!-- Error state -->
            <div v-if="loginServerError" class="error-label">
              {{ $t("error_status.unauthorized_error") }}<br>
              <p>{{ loginServerError.text }}</p>
            </div>
            <div v-else-if="loginRequestError" class="error-label">
              {{ $t("login.request_error") }} <br> <code>{{ loginRequestError }}</code>
            </div>

            <hr class="w-100" />
            <router-link to="/new-password" name="new-password">
              {{ $t("login.cant_login") }}
            </router-link>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import {
  ACCOUNT_SERVICE_TOKEN_KEY,
  ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY,
  ACCOUNT_SERVICE_password_change_required,
  ACCOUNT_SERVICE_LANGUAGE
} from "../constants";
import BGBackground from "./components/BGBackground.vue";
import BGHeaderLogo from "./components/BGHeaderLogo.vue";

export default {
  bodyClass: "register-page",

  components: {
    BGBackground,
    BGHeaderLogo
  },

  data() {
    return {
      // Login form data
      emailOrUsername: null,
      emailOrUsernameVisited: false,
      password: null,
      passwordVisited: false,
      showPassword: false,
      rememberMe: true,
      password_change_required: false,
      userId: null,
      language: null,

      // Form state
      loginLoading: false,
      formError: null,
      capsLockWarning: false,

      // Login errors
      loginServerError: null,
      loginRequestError: null,
      loginRequestConfigError: null,
      loginErrorBoolean: false,
      oAuth2ErrorBoolean: false,
      tokenRequestBoolean: false,

      //URL
      clientId: this.$route.query.client_id,
      response_type: this.$route.query.response_type,
      scope: this.$route.query.scope,
      redirectUri: this.$route.query.redirect_uri,
      forwarding: null,
      state: null
    };
  },

  computed: {
    // Client-side email/username validation
    emailOrUsernameError() {
      if (!this.emailOrUsernameVisited) {
        return null;
      } else if (!this.emailOrUsername) {
        return this.$t("login.missing_email_name");
      } else if (
        this.emailOrUsername.length &&
        this.emailOrUsername.length <= 2) {
        return this.$t("login.two_character");
      } else if (!this.isValidEmail(this.emailOrUsername)) {
        return this.$t("error_status.invalid_email");
      } else {
        return null;
      }
    },
    // Client-side password validation
    passwordError() {
      if (!this.passwordVisited) {
        return null;
      } else if (!this.password) {
        return this.$t("error_status.password");;
      } else if (this.password.length && this.password.length <= 5) {
        return this.$t("error_status.short_password");;
      } else {
        return null;
      }
    }
  },

  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },

  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async login() {
      if (this.emailOrUsernameError) {
        this.formError = this.$t("error_status.missing_email");
      } else if (this.passwordError) {
        this.formError = this.$t("error_status.password");
      } else {
        try {
          // Loading state
          this.loginLoading = true;

          const base_url = this.$store.getters.settings.api_url;
          const response = await axios.post(`${base_url}api/login`, {
            username: this.emailOrUsername,
            password: this.password
          });
          const token = response.data.access_token;
          this.password_change_required = response.data.password_change_required;

          await this.whoAmI(token);
          await this.languageAS(token);

          _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
          _paq.push(['setUserId', this.userId]);
          _paq.push(['trackEvent', 'API/Login', 'Successful']);

          this.handleLoginSuccess(token);
        } catch (error) {
          this.loginErrorBoolean = true;
          this.handleLoginError(error);
          if (this.loginServerError.data.detail.remaining_attempts !== undefined) {
            _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
            _paq.push(['trackEvent', 'API/Login', 'Error', this.loginServerError.status + ' - remaining_attempts: ' + this.loginServerError.data.detail.remaining_attempts]);
          } else {
            _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
            _paq.push(['trackEvent', 'API/Login', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail]);

          }

        }
      }
    },

    async handleLoginSuccess(token) {
      const base_url = this.$store.getters.settings.api_url;

      if (this.rememberMe) {
        localStorage.setItem(ACCOUNT_SERVICE_TOKEN_KEY, token);
        localStorage.setItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY, this.emailOrUsername);
        localStorage.setItem(ACCOUNT_SERVICE_password_change_required, this.password_change_required);
        localStorage.setItem(ACCOUNT_SERVICE_LANGUAGE, this.language);
      } else {
        sessionStorage.setItem(ACCOUNT_SERVICE_TOKEN_KEY, token);
        sessionStorage.setItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY, this.emailOrUsername);
        sessionStorage.setItem(ACCOUNT_SERVICE_password_change_required, this.password_change_required);
        sessionStorage.setItem(ACCOUNT_SERVICE_LANGUAGE, this.language);
      }

      try {
        if (this.clientId) {
          await this.handleSitesLogin(token, base_url, this.clientId);
        } else {
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        console.error(error);
      }

    },

    async handleSitesLogin(token, base_url, client_id) {
      let trusted;
      try {
        const dashboard = await axios.get(`${base_url}api/oauth2/clients/${client_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        trusted = dashboard.data.trusted;

        _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
        _paq.push(['setUserId', this.userId]);
        _paq.push(['trackEvent', 'API/OAuth2/' + client_id, 'Successful', 'Trusted:' + trusted]);
      } catch (error) {
        this.oAuth2ErrorBoolean = true;
        this.handleLoginError(error);

        _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
        _paq.push(['trackEvent', 'API/OAuth2/' + client_id, 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail]);
        
        if (this.loginServerError.status == 403 && this.loginServerError.data.detail == "Password change required") {
          this.password_change_required = true;
          localStorage.setItem(ACCOUNT_SERVICE_password_change_required, this.password_change_required);
          this.$router.push(`/change-password?${this.$route.fullPath}`);
        }
      }

      if (trusted) {
        const jsonData = {
          client_id: this.clientId,
          response_type: this.response_type,
          scope: this.scope.split(" "),
          redirect_uri: this.redirectUri,
        };

        if (this.state) {
          jsonData.state = this.state;
        }

        try {
          const responseOA2 = await axios.post(`${base_url}api/oauth2/authorize`, jsonData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const tokenOA2 = responseOA2.data.access_token;
          const expire_time = responseOA2.data.expires_in;
          const scope = responseOA2.data.scope;
          const formattedScope = scope.join('+');
          
          if (responseOA2.data && responseOA2.data.state) {
            const state = responseOA2.data.state;
            this.forwarding = `${this.redirectUri}#access_token=${tokenOA2}&token_type=Bearer&expires_in=${expire_time}&scope=${formattedScope}&state=${state}`;
          } else {
            this.forwarding = `${this.redirectUri}#access_token=${tokenOA2}&token_type=Bearer&expires_in=${expire_time}&scope=${formattedScope}`;
          }

          _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
          _paq.push(['setUserId', this.userId]);
          _paq.push(['trackEvent', 'API/OAuth2/authorize', 'Successful']);
          location.replace(this.forwarding);
        } catch (error) {
          this.tokenRequestBoolean = true;
          this.handleLoginError(error);

          _paq.push(['setCustomDimension', 1, this.emailOrUsername]);
          _paq.push(['trackEvent', 'API/OAuth2/authorize', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail]);
        }
      }
    },

    handleLoginError(error) {
      this.loginLoading = false;
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        // Handle other response status codes
        this.loginServerError = {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers
        };

        if (this.loginErrorBoolean) {
          if (this.loginServerError.status == 401) {
            if (this.loginServerError.data.detail == "1bfec375889456a00f9e76208df9f09d"
              || this.loginServerError.data.detail == "8c16dc257d2bd3a14c215bbadd6d6411") {
              this.loginServerError.text = this.$t('error_text.invalid_email');
            }
            if (this.loginServerError.data.detail.remaining_attempts !== undefined) {
              const remainingAttempts = this.loginServerError.data.detail.remaining_attempts;
              this.loginServerError.text = this.$t('error_text.remaining_attempts', { remainingAttempts });;
            }
            if (this.loginServerError.data.detail == "Account locked" || this.loginServerError.data.detail == "Locked out") {
              this.loginServerError.text = this.$t('error_text.account_lockout', { email: "support@benchmark.games" });
            }
            if (this.loginServerError.data.detail == "4157e5088038fcb9639d0a67f82bbde3"
              || this.loginServerError.data.detail == "637905cbbdef76bf168f9a036968526a"
              || this.loginServerError.data.detail == "bb996a531afec7056344afa3e69092cc"
              || this.loginServerError.data.detail == "9a10f345b0f755d461a8673a5e9ef6b4"
              || this.loginServerError.data.detail == "aadf556bd097b61c56920ef42829167a") {
              this.loginServerError.text = this.$t('error_text.something_wrong_try', { email: "support@benchmark.games" }) + this.loginServerError.data.detail;
            }
          }
        }
        else if (this.oAuth2ErrorBoolean) {
          if (this.loginServerError.status == 401) {
            if (this.loginServerError.data.detail == "Not authenticated" || this.loginServerError.data.detail == "Invalid token") {
              this.loginServerError.text = this.$t('error_text.oauth2_mail');
            }
          }
        }
        else if (this.tokenRequestBoolean) {
          if (this.loginServerError.status == 401) {
            if (this.loginServerError.data.detail == "Not authenticated" || this.loginServerError.data.detail == "Invalid token") {
              this.loginServerError.text = this.$t('error_text.oauth2_mail');
            }
          }
        }
        if (!this.loginServerError.text) {
          this.loginServerError.text = this.$t('error_text.something_wrong', { email: "support@benchmark.games" });
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        this.loginRequestError = error.message;
      } else {
        // Something happened in setting up the request that triggered an Error
        this.loginRequestConfigError = error;
      }
      this.tokenRequestBoolean = false;
      this.oAuth2ErrorBoolean = false;
      this.loginErrorBoolean = false;
    },
    localStorageDelete() {
      localStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
      localStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);
      localStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
      localStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);
      sessionStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
      sessionStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);
      sessionStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
      sessionStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);
    },
    async languageAS(token) {
      try {
        const base_url = this.$store.getters.settings.api_url;
        const responseLanguage = await axios.get(`${base_url}api/settings/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (responseLanguage.data && responseLanguage.data.global && responseLanguage.data.global.language) {
          this.language = responseLanguage.data.global.language;
          localStorage.setItem(ACCOUNT_SERVICE_LANGUAGE, this.language);
          sessionStorage.setItem(ACCOUNT_SERVICE_LANGUAGE, this.language);
        }
      } catch (error) {
        this.handleLoginError(error);
        this.localStorageDelete();
        _paq.push(['trackEvent', 'API/Login/Settings', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail + ' - Token: ' + token]);

        this.$router.push(`/login`);
      }
    },
    async whoAmI(token) {
      try {
        const base_url = this.$store.getters.settings.api_url;
        const response = await axios.get(`${base_url}api/whoami`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.email) {
          this.email = response.data.email;
        }
        if (response.data && response.data.uid) {
          this.userId = response.data.uid;
        }
      } catch (error) {
        this.handleLoginError(error);
        this.localStorageDelete();
        _paq.push(['trackEvent', 'API/Login/WhoAmI', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail + ' - Token: ' + token]);

        this.$router.push(`/login`);
      }
    },
    handleKeyDown(event) {
      /* See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState
      This function is supported in Firefox & MS Edge, webkit browsers
      will be supported (soon) */
      if (event.getModifierState("CapsLock")) {
        this.capsLockWarning = true;
      } else {
        this.capsLockWarning = false;
      }
    },
  },
  mounted() {
    const token = localStorage.getItem('BG-account-service-token');
    this.loginLoading = true;
    if (this.$route.query.state) {
      this.state = this.$route.query.state;
    }
    if (token) {
      const base_url = this.$store.getters.settings.api_url;
      this.whoAmI(token);
      this.languageAS(token);
      this.handleSitesLogin(token, base_url, this.clientId);
    } else {
      this.loginLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  color: white;
}

.error-label {
  padding-top: 32px;
  color: #ed4063;

  p {
    margin: 20px 0 0;
  }
}

.input-error {
  border: 1px solid #ed4063;
}

#login-button {
  font-size: 16px;
}

.form-loading-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.2);
}

.password-input-group {
  .form-control {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .form-control:focus {
    padding-right: 45px;
  }

  .password-visibility {
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin: auto 10px auto -35px;

    z-index: 3;
  }

  .password-visibility:hover {
    opacity: 0.7;
  }
}

.form-capslock-label {
  background: #ff9452;
  border-radius: 4px;
  padding: 4px;
  color: #ffffff;
  position: relative;
  top: -53px;
  left: 200px;
  z-index: 4;
  width: 140px;
  margin-bottom: -32px;
  text-align: center;
}
</style>

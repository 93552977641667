// plugins/i18n.js
import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
import hu from '../locales/hu.json';

const userLang = navigator.language.split('-')[0];
const locale = userLang === 'hu' ? 'hu' : 'en';

const i18n = createI18n({
  legacy: false,
  locale,
  messages: {
    en,
    hu,
  },
});

export default i18n;

/* eslint-disable no-plusplus */
import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      // Állapotok
      settings: null,
      settingsError: null,
    };
  },
  getters: {
    settings(state) {
      return state.settings;
    },
    settingsError(state) {
      return state.settingsError;
    },
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    setSettingsError(state, settingsError) {
      state.settingsError = settingsError;
    },
  },
});

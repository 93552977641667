<template>
  <router-view name="header"></router-view>
  <main>
    <router-view />
  </main>
  <router-view name="footer"></router-view>
</template>
<script>
export default {
  methods: {
    setFavicon() {
      const environment = this.$store?.getters?.settings?.environment;
      let iconPath;
      
      if (environment === "production") {
        iconPath="favicon.ico";
      } else if (environment === "staging") {
        iconPath="favicon-staging.ico";
      } else {
        iconPath="favicon-dev.ico";
      }
      const link = document.querySelector("#app-favicon");
      if (link) {
        link.href = iconPath;
      }
    },
  },
  mounted() {
    this.setFavicon();
  },
};
</script>
<template>
    <div class="wrapper">
        <div class="page-header bg-default">
            <BGBackground />
            <BGHeaderLogo />

            <div v-if="passwordloadingsite" class="container" id="container">
                <div class="form-container sign-in-container">
                    <form class="newPassword" action="#" role="form">
                        <div v-if="email" class="email_div">
                            <p>{{ email }}</p>
                        </div>
                        <h5>{{ $t("new_password.set_new_password") }}</h5>
                        <img class="pipa" src="../assets/img/icons/pipa.svg" alt="Warning Icon" />
                        <p class="new_password_p">
                            {{ $t("new_password.sec_set_password") }}
                        </p>
                        <base-button id="login-button" type="bg-gradient" class="w-100" size="lg" data-cy="login"
                            @click="loginRouter">
                            <span>{{ $t("continue") }}</span>
                        </base-button>
                    </form>
                </div>
            </div>
            <div v-else class="container" id="container">
                <div class="form-container sign-in-container">
                    <form class="newPassword" action="#" role="form">
                        <div v-if="email" class="email_div">
                            <p>{{ email }}</p>
                        </div>
                        <h5 v-if="password_change_required">{{ $t("change_password.password_change_requied") }}</h5>
                        <h5 v-else>{{ $t("change_password.password_change_comp") }}</h5>
                        <p v-if="password_change_required" class="new_password_p">{{ $t("change_password.cybersec") }}</p>
                        <div class="checking" :class="{ fulfilled: requirementsFulfilled }">
                            <div v-for="(requirement, index) in requirements" :key="index">
                                <div class="icon">
                                    <img v-if="requirement.fulfilled" src="../assets/img/icons/success.svg"
                                        alt="Success Icon" />
                                    <img v-else src="../assets/img/icons/warning.svg" alt="Warning Icon" />
                                </div>
                                <p>{{ requirement.text }}</p>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <label>{{ $t("change_password.old_password") }}:</label>
                            <div class="email-username--input-group input-group mb-3">
                                <input v-model="oldPassword" :type="showOldPassword ? 'text' : 'password'"
                                    class="form-control" label="OldPassword" aria-label="OldPassword"
                                    data-cy="Oldpassword" />
                                <img class="password-visibility" :src="showOldPassword
                                    ? '/img/ill/visibility-off.svg'
                                    : '/img/ill/visibility-on.svg'
                                    " @click="showOldPassword = !showOldPassword" />
                            </div>
                            <label>{{ $t("new_password.new_pass") }}:</label>
                            <div class="email-username--input-group input-group mb-3">
                                <input v-model="newPassword" :class="{
                                    'input-error': !!passwordError
                                }" :type="showPassword ? 'text' : 'password'" class="form-control" label="NewPassword"
                                    aria-label="NewPassword" data-cy="Newpassword" />
                                <img class="password-visibility" :src="showPassword
                                    ? '/img/ill/visibility-off.svg'
                                    : '/img/ill/visibility-on.svg'
                                    " @click="showPassword = !showPassword" />
                            </div>
                            <label>{{ $t("new_password.new_pass_again") }}:</label>
                            <div class="email-username--input-group input-group mb-3">
                                <input v-model="newPasswordAgain" :class="{
                                    'input-error': !!passwordError
                                }" :type="showPasswordAgain ? 'text' : 'password'" class="form-control"
                                    label="NewPassword" aria-label="NewPassword" data-cy="Newpassword" />
                                <img class="password-visibility" :src="showPasswordAgain
                                    ? '/img/ill/visibility-off.svg'
                                    : '/img/ill/visibility-on.svg'
                                    " @click="showPasswordAgain = !showPasswordAgain" />
                            </div>
                            <div v-if="passwordError" class="form-error-label">
                                {{ passwordError }}
                            </div>
                        </div>
                        <div v-if="loginServerError" class="error-label">
                            {{ $t("error_status.unauthorized_error") }}<br>
                            <p>{{ loginServerError.text }}</p>
                        </div>
                        <div v-else-if="loginRequestError" class="error-label">
                            {{ $t("login.request_error") }} <br> <code>{{ loginRequestError }}</code>
                        </div>
                        <base-button id="login-button" type="bg-gradient" class="w-100" size="lg" data-cy="login"
                            @click="passwordChangeRequest" :disabled="!requirementsFulfilled || passwordError">
                            <span v-if="!passwordLoading">{{ $t("button.set_new_password") }}</span>
                            <span v-else>{{ $t("loading") }}...</span>
                        </base-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import {
    ACCOUNT_SERVICE_TOKEN_KEY,
    ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY,
    ACCOUNT_SERVICE_password_change_required,
    ACCOUNT_SERVICE_LANGUAGE
} from "../constants";
import BGBackground from "./components/BGBackground.vue";
import BGHeaderLogo from "./components/BGHeaderLogo.vue";
import LoadingRing from "@/components/LoadingRing.vue";

export default {
    bodyClass: "register-page",

    components: {
        BGBackground,
        BGHeaderLogo,
        LoadingRing
    },

    data() {
        return {
            // Reset password form data
            newPassword: null,
            newPasswordAgain: null,
            oldPassword: null,
            userId: null,
            email: null,

            // Form error
            formError: null,

            //Errors
            loginServerError: null,
            loginRequestError: null,
            loginRequestConfigError: null,

            // Reset result
            passwordloadingsite: false,
            password_change_required: false,

            // Reset password errors
            resetPasswordServerError: null,
            resetPasswordRequestError: null,
            resetPasswordRequestConfigError: null,

            //requirements
            requirements: [
                { text: this.$t('new_password.min8Chars'), fulfilled: false },
                { text: this.$t('new_password.uppercaseLowercase'), fulfilled: false },
                { text: this.$t('new_password.specialCharacter') + ' (!, %, @, ?, *, etc.)', fulfilled: false },
                { text: this.$t('new_password.numbers'), fulfilled: false },
            ],

            //Other
            loading: false,
            showPassword: false,
            showPasswordAgain: false,
            showOldPassword: false,
            passwordVisited: false,
            passwordError: null,
            passwordLoading: false,
            email: null,
            queryparams: false,
        };
    },
    watch: {
        newPassword: function (newVal, oldVal) {
            if (newVal == this.newPasswordAgain) {
                this.passwordError = null;
            } else {
                this.passwordError = "A jelszavak nem egyeznek meg";
            }
            this.requirements.forEach(requirement => {
                requirement.fulfilled = false;
            });
            this.checkPasswordRequirements(newVal);
        },
        newPasswordAgain: function (newVal, oldVal) {
            if (newVal == this.newPassword) {
                this.passwordError = null;
            } else {
                this.passwordError = "A jelszavak nem egyeznek meg";
            }
        },
        '$i18n.locale': 'updateRequirements',
    },
    computed: {
        requirementsFulfilled() {
            return this.requirements.every((requirement) => requirement.fulfilled);
        },
    },
    methods: {
        localStorageDelete() {
            localStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
            localStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);
            localStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
            localStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);
            sessionStorage.removeItem(ACCOUNT_SERVICE_TOKEN_KEY);
            sessionStorage.removeItem(ACCOUNT_SERVICE_EMAIL_OR_USERNAME_KEY);
            sessionStorage.removeItem(ACCOUNT_SERVICE_password_change_required);
            sessionStorage.removeItem(ACCOUNT_SERVICE_LANGUAGE);
        },
        updateRequirements() {
            // Frissítés a nyelvváltás után
            this.requirements = [
                { text: this.$t('new_password.min8Chars'), fulfilled: false },
                { text: this.$t('new_password.uppercaseLowercase'), fulfilled: false },
                { text: this.$t('new_password.specialCharacter') + ' (!, %, @, ?, *, etc.)', fulfilled: false },
                { text: this.$t('new_password.numbers'), fulfilled: false },
            ];
        },
        loginRouter() {
            if (this.queryparams) {
                const fullPath = this.$route.fullPath;
                const queryString = fullPath.substring(fullPath.indexOf('?') + 1);
                this.$router.push(queryString);
            } else {
                this.$router.push(`/login`);
            }
        },
        checkPasswordRequirements(password) {
            if (password) {
                this.requirements[0].fulfilled = password.length >= 8;
                this.requirements[1].fulfilled = /[a-z]/.test(password) && /[A-Z]/.test(password);
                this.requirements[2].fulfilled = /[!@#$%^&*(),.?":{}|<>'"+]/.test(password);
                this.requirements[3].fulfilled = /\d/.test(password);
            }
        },
        async passwordChangeRequest() {
            if (!this.passwordError && this.requirementsFulfilled) {
                this.passwordLoading = true;
                try {
                    const base_url = this.$store.getters.settings.api_url;
                    await axios.post(`${base_url}api/user/${this.userId}/change-password`, {
                        new_password: this.newPassword,
                        old_password: this.oldPassword,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY)}`,
                        },
                    });

                    this.passwordloadingsite = true;
                    if (this.password_change_required) {
                        this.localStorageDelete();
                    }
                    _paq.push(['setCustomDimension', 1, this.email]);
                    _paq.push(['setUserId', this.userId]);
                    _paq.push(['trackEvent', 'API/Change Password', 'Successful']);
                } catch (error) {
                    this.handleLoginError(error);
                    _paq.push(['setCustomDimension', 1, this.email]);
                    _paq.push(['setUserId', this.userId]);
                    _paq.push(['trackEvent', 'API/Change Password', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail]);
                }
            }
        },
        async whoAmI() {
            try {
                const base_url = this.$store.getters.settings.api_url;
                const response = await axios.get(`${base_url}api/whoami`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY)}`,
                    },
                });

                if (response.data && response.data.email) {
                    this.email = response.data.email;
                }
                if (response.data && response.data.uid) {
                    this.userId = response.data.uid;
                }
            } catch (error) {
                this.handleLoginError(error);
                this.localStorageDelete();

                _paq.push(['trackEvent', 'API/Change Password/WhoAmI', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail + ' - Token: ' + localStorage.getItem(ACCOUNT_SERVICE_TOKEN_KEY)]);
                this.$router.push(`/login`);
            }
        },
        handleLoginError(error) {
            this.passwordLoading = false;
            this.passwordloadingsite = false;
            if (error.response) {
                this.loginServerError = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                };

                if (this.loginServerError.status == 406) {
                    if (this.loginServerError.data.reason == "SAME_AS_OLD") {
                        this.loginServerError.text = this.$t('error_text.same_as_old');
                    }
                }

                if (!this.loginServerError.text) {
                    this.loginServerError.text = this.$t('error_text.something_wrong', { email: "support@benchmark.games" } );
                }
            } else if (error.request) {
                this.loginRequestError = error.message;
            } else {
                this.loginRequestConfigError = error;
            }
        },
    },
    mounted() {
        if (this.$route.query.redirect_uri) {
            this.queryparams = true;
        }

        if (localStorage.getItem(ACCOUNT_SERVICE_password_change_required) != "undefined") {
            this.password_change_required = localStorage.getItem(ACCOUNT_SERVICE_password_change_required);
        }
        this.whoAmI();
        if (this.password_change_required) {
            window.addEventListener('beforeunload', this.localStorageDelete);
            window.addEventListener('unload', this.localStorageDelete);

        }
    },

    beforeDestroy() {
        if (this.password_change_required) {
            window.removeEventListener('beforeunload', this.localStorageDelete);
            window.removeEventListener('unload', this.localStorageDelete);

        }
    },
};
</script>
  
<style lang="scss" scoped>
button {
    color: white;
}

.pipa {
    width: 83px;
}

.newPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;


    .new_password_p {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .email_div {
        display: flex;
        align-items: center;

        width: auto;
        height: 2rem;
        border-radius: 2rem;
        border: 1px solid #525f7f;
        padding: 0 1rem;

        p {
            width: auto;
            color: #525f7f;
            margin: 0;

            text-align: center;
            font-family: Rubik;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;

        }
    }

    .checking {
        display: flex;
        width: 21.4375rem;
        padding-top: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;

        div {
            display: flex;
            align-items: center;
            gap: 1rem;

            p {
                margin: 0;
                font-family: Rubik;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4375rem;
            }

            .icon {
                width: 1.1875rem;
                height: 1.1875rem;
            }
        }
    }
}

.error-label {
    color: #ed4063;
}

.password-visibility {
    cursor: pointer;
    width: 25px;
    height: 25px;
    margin: auto 10px auto -35px;

    z-index: 3;
}

.password-visibility:hover {
    opacity: 0.7;
}

.input-error {
    border: 1px solid #ed4063;
}
</style>
  
<template>
  <router-link class="login-brand" to="/" name="home">
    <img :src="logoSrc" alt="logo" />
  </router-link>
  <LanguageSelector />
</template>

<script>
import LanguageSelector from '../components/LanguageSelector.vue';

export default {
  computed: {
    logoSrc() {
      const environment = this.$store?.getters?.settings?.environment;
      if (environment === "production") {
        return "/img/brand/benchmark-games.png";
      } else if (environment === "staging") {
        return "/img/brand/benchmark-games-staging.png";
      } else {
        return "/img/brand/benchmark-games-dev.png";
      }
    }
  },
  components: {
    LanguageSelector,
  },
};
</script>

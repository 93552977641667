<template>
  <div class="wrapper">
    <div class="page-header bg-default">
      <BGBackground />
      <BGHeaderLogo />

      <div class="container" id="container">
        <div v-if="resetEmailSent" class="form-container sign-in-container">
          <form action="#" role="form">
            <h5>{{ $t("reset_password.check_inbox") }}</h5>
            <img class="inbox-icon" src="@/assets/img/inbox.svg" alt="HU Flag" />
            <p v-html="$t('reset_password.email_sent_message', { email: '<b>support@benchmark.games</b>' })"></p>
            <!--
            <div class="email--input-group input-group mb-3">
                <input
                  type="text"
                  :class="{ 'input-error': !!emailError }"
                  class="form-control"
                  size="lg"
                  label="security_code"
                  aria-label="security_code"
                  data-cy="security_code"
                  placeholder="Security Code"
                  @blur="emailVisited = true"
                />
            </div>-->
            <LoadingRing v-if="loading" class="pagination-wrapper"></LoadingRing>
            <img v-else class="pipa" src="../assets/img/icons/pipa.svg" alt="Warning Icon" />
            <p v-if="!loading" class="click_again fade" :class="{'fade': loading }">
              {{ $t("reset_password.didnt_email") }}
            <p v-if="!loading" class="resend_email fade" @click="sendResetEmailAgain">{{ $t("reset_password.resend_email") }}</p>
            </p>
          </form>
        </div>
        <div v-else class="form-container sign-in-container">
          <form action="#" role="form">
            <h5>{{ $t("reset_password.reset_your") }}</h5>
            <p>
              {{ $t("reset_password.please_enter") }}
            </p>
            <div class="form-group mb-0">
              <!-- Email -->
              <label>E-mail</label>
              <div class="email--input-group input-group mb-3">
                <input v-model="email" type="text" :class="{ 'input-error': !!emailError }" class="form-control"
                  label="Email" aria-label="Email" data-cy="email" @blur="emailVisited = true" />
              </div>
              <div v-if="emailError" class="form-error-label">
                {{ emailError }}
              </div>
            </div>
            <base-button id="reset-password-btn" type="bg-gradient" class="mt-3 w-100" data-cy="reset-password"
              @click="passwordResetRequest" :disabled="submitDisabled">
              {{ loading ? $t("loading") : $t("reset_password.reset_your") }}
            </base-button>

            <!-- Error state -->
            <div v-if="loginServerError" class="error-label">
              {{ $t("error_status.unauthorized_error") }}<br>
              <p>{{ loginServerError.text }}</p>
            </div>
            <div v-else-if="loginRequestError" class="error-label">
              {{ $t("login.request_error") }} <br> <code>{{ loginRequestError }}</code>
            </div>
          </form>
        </div>
        <div class="back_login">
          <hr class="w-100" />
          <router-link to="/login" name="login">
            {{ $t("reset_password.back_login") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import BGBackground from "./components/BGBackground.vue";
import BGHeaderLogo from "./components/BGHeaderLogo.vue";
import LoadingRing from "@/components/LoadingRing.vue";

export default {
  bodyClass: "register-page",

  components: {
    BGBackground,
    BGHeaderLogo,
    LoadingRing
  },

  data() {
    return {
      // Reset password form data
      email: null,
      emailVisited: false,

      // Form error
      formError: null,

      //Errors
      loginServerError: null,
      loginRequestError: null,
      loginRequestConfigError: null,
      passwordResetBoolean: false,

      // Reset result
      resetEmailSent: false,

      // Reset password errors
      resetPasswordServerError: null,
      resetPasswordRequestError: null,
      resetPasswordRequestConfigError: null,

      passwordResetButtonClicked: false,
      loading: false,
    };
  },

  computed: {
    // Client-side email/username validation
    emailError() {
      if (!this.emailVisited) {
        return null;
      } else if (!this.email || (this.email.length && this.email.length <= 2) || !this.isValidEmail(this.email)) {
        return this.$t("error_status.missing_email");
      } else {
        return null;
      }
    },
    redirectUri() {
      return this.$route.query.redirect_uri;
    },
    submitDisabled() {
      return this.loading || !!this.emailError || this.emailVisited === false || this.passwordResetButtonClicked;
    }
  },

  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async passwordResetRequest() {
      if (this.emailError) {
        this.formError = this.$t("error_status.missing_email");
      } else if (!this.loading && !this.passwordResetButtonClicked) {
        this.passwordResetButtonClicked = true;
        this.loading = true;
        try {
          const base_url = this.$store.getters.settings.api_url;
          await axios.post(`${base_url}api/user/reset-password-token`, {
            user_identifier: this.email
          });

          this.resetEmailSent = true;
          _paq.push(['setCustomDimension', 1, this.email]);
          _paq.push(['trackEvent', 'API/Reset Password Token', 'Successful']);
        } catch (error) {
          this.passwordResetBoolean = true;
          this.handleLoginError(error);
          _paq.push(['trackEvent', 'API/Reset Password Token', 'Error', this.loginServerError.status + ' - ' + this.loginServerError.data.detail]);
        } finally {
          setTimeout(() => {
            this.loading = false;
          }, 4000);
        }
      }
    },
    sendResetEmailAgain() {
      this.passwordResetButtonClicked = false;
      this.passwordResetRequest();
    },
    handleLoginError(error) {
      this.loginLoading = false;
      if (error.response) {
        this.loginServerError = {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers
        };

        if (this.passwordResetBoolean) {
          if (this.loginServerError.status == 412) {
            if (this.loginServerError.data.detail == "No email address") {
              this.loginServerError.text = this.$t('error_text.no_email');
            }
          }
          if (this.loginServerError.status == 500) {
            if (this.loginServerError.data.detail == "Sending mail failed") {
              this.loginServerError.text = this.$t('error_text.sending_mail_failed', { email: "support@benchmark.games" });
            }
          }
        }
        if (!this.loginServerError.text) {
          this.loginServerError.text = this.$t('error_text.something_wrong', { email: "support@benchmark.games" });
        }
      } else if (error.request) {
        this.loginRequestError = error.message;
      } else {
        this.loginRequestConfigError = error;
      }
      this.passwordResetBoolean = false;
    },
  }
};
</script>

<style lang="scss" scoped>
button {
  color: white;
}

#reset-password-btn {
  height: 52px;
}

.error-label {
  padding-top: 32px;
  color: #ed4063;
}

.input-error {
  border: 1px solid #ed4063;
}

.inbox-icon {
  margin: 1rem 0;
}

.back_login {
  text-align: center;
}

.fade {
  animation: fadeIn 1s forwards; 
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.click_again {
  margin-bottom: 0;
  display: flex;
  width: 19.1875rem;
  height: 2.625rem;
  flex-direction: column;
  justify-content: center;


  p {
    color: var(--visual-ppl, #956DF4);
    text-decoration-line: underline;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.pipa {
  color: #A3ABBF;
  width: 30px;
}
</style>

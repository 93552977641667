<template>
  <div class="language_div">
    <button :class="{ 'active': currentLanguage === 'hu' }" @click="changeLanguage('hu')">
      <img src="@/assets/img/flags/hu.svg" alt="HU Flag" /> HU
    </button>
    <button :class="{ 'active': currentLanguage === 'en' }" @click="changeLanguage('en')">
      <img src="@/assets/img/flags/en.svg" alt="EN Flag" /> EN
    </button>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      currentLanguage: this.$i18n.locale,
    };
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.currentLanguage = locale;
    },
  },
};
</script>

<style lang="scss" scoped>
.language_div {
  padding: 0 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;


  button {
    display: flex;
    align-items: center;
    margin-right: 10px;
    background: none;
    border: none;
    cursor: pointer;

  }
  button.active {
    text-decoration: underline;
    font-weight: bold;
  }

  img {
    width: 20px;
    height: auto;
    margin-right: 5px;
  }
}
</style>
  